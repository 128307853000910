@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "transitions.css";

body {
    font-family: "Inter var", sans-serif;
    font-weight: 400;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

.bg-linear-gradient {
    background: -webkit-linear-gradient(73deg, rgba(76, 99, 182, 1) 0%, rgba(76, 99, 182, 1) 17%, rgba(45, 58, 140, 1) 42%, rgba(25, 35, 108, 1) 38%, rgba(25, 35, 108, 1) 62%)
}

.h-400px {
    height: 240px;
}

.h-780px {
    height: 780px;
}

.max-h-sc {
    overflow-y: auto;
}

.min-100vh {
    min-height: 100vh;
}

.card-fixed-bottom {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
}

.card-animation:hover {
    transition: .2s all ease;
}

.modal {
    background: rgba(44, 55, 66, .3);
}

.modal-card {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 20px;
    margin-bottom: 20px;
}

.background-auth {
    background: url("/img/background.svg") center center no-repeat;
    width: 100%;
    background-size: cover;
}

.text-md {
    font-size: 16px;
}

.bg-tree {
    background: url("/img/form-bg.svg") no-repeat center center;
    width: 100%;
    background-size: cover;
}

.tree-img {
    height: 430px;
}

.l-input:focus {
    border: 1px solid #4c63b6 !important;
}

.small-nav {
    display: flex;
}

.fixed-sidebar {
    position: fixed;
    top: 64px;
    bottom: 64px;
}

.main-tree {
    margin-left: 33.333333%;
}

@media only screen and (max-width: 1600px) {
    .years-sm {
        font-size: 10px;
    }

    .drop-box {
        display: none;
    }

}

@media only screen and (max-height: 640px) {
    .max-h-600px {
        max-height: 420px;
    }
}

@media only screen and (max-width: 1280px) {
    .main-tree {
        margin-left: 50%;
    }
}


@media only screen and (max-width: 1024px) {
    .tree-content-sm {
        display: none !important;
    }

    .max-h-sc {
        width: 100% !important;
        max-height: unset !important;
    }

    .fixed-sidebar {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1023px) {
    .h-sidebar {
        height: auto;
    }

    .small-nav {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .input-box {
        margin-left: 0 !important;
    }

    .modal-input {
        width: 100%;
    }

    .input-container {
        flex-direction: column;
    }

    .select-container {
        align-items: baseline;
    }

    .m-input {
        margin-bottom: 1rem;
    }

    .modal-select {
        height: 2rem;
        width: 100%;
    }

    .m-select {
        width: 100%;
    }

    .modal-card {
        top: 0;
        transform: unset;
        max-width: 550px;
    }
}

.footer {
    position: fixed;
    bottom: 0;
}

@media only screen and (max-width: 640px) {
    .tree-content-sm {
        display: none;
    }

    .navbar-sm {
        flex-direction: column;
    }

    .side-content-sm {
        width: 100%;
    }

    .hide-avatar {
        display: none;
    }

    .logo-sm {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: .5rem 2rem;
    }

    .logo-box-sm {
        flex: 1;
    }

    .toggle-sm {
        margin-left: 1rem;
    }

    .links-sm {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 2.5rem;
    }

    .links-sm > div {
        margin-bottom: .5rem;
    }

    .card-width-sm {
        max-width: 400px;
        flex-direction: column;
        padding: 2rem;
        bottom: -50px;
    }

    .input-sm {
        width: 100%;
        margin-right: 0;
    }

    .button-sm {
        width: 100%;
        margin-top: 1rem;
    }

    .title-small {
        font-size: 2rem;
        text-align: center;
    }

    .hero-sm {
        height: 400px;
        padding: 50px 10px;
        justify-content: flex-start;
    }

    .column-sm {
        flex-direction: column;
    }

    .center-sm {
        text-align: center;
    }

    .card-animation {
        padding: 1rem;
    }

    .bg-tree {
        display: none;
    }

    .login-title {
        font-size: 25px;
    }

    .p-login {
        padding: 2rem !important;
    }

    .remember-sm {
        flex-direction: column;
    }

    .fg-password {
        margin-top: .5rem;
    }

    .links-sm {
        display: block;
    }

    .small-search {
        display: flex;
        flex-direction: column;
    }

    .btn-full {
        width: 100%;
    }
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e3342f;
}

.main-content {
    margin-bottom: 64px;
}

.dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
}

.transition-all {
    transition-property: all;
}

.transition-fastest {
    transition-duration: 50ms;
}

.transition-faster {
    transition-duration: 100ms;
}

.transition-fast {
    transition-duration: 150ms;
}

.transition-medium {
    transition-duration: 200ms;
}

.ease-out-quad {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

.ease-in-quad {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

.scale-70 {
    transform: scale(.7);
}

.scale-100 {
    transform: scale(1);
}

/*Marko custom css start*/
@responsive {
    .min-h-full-without-nav {
        min-height: calc(100vh - theme(spacing['h-header']));
    }

    .w-playground {
        width: calc(100% - theme('w-sidebar'));
    }

    .h-full-without-nav {
        height: calc(100vh - theme(spacing['h-header']));
    }
}

.btn-indigo {
    @apply py-1 px-1 m-1 bg-blue-500 text-white text-sm shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}

[x-cloak] { display: none !important; }
